import { render, staticRenderFns } from "./KycStatus.vue?vue&type=template&id=949a5722&"
import script from "./KycStatus.vue?vue&type=script&lang=js&"
export * from "./KycStatus.vue?vue&type=script&lang=js&"
import style0 from "./KycStatus.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports